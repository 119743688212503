import React, { useCallback } from "react";
import config from "../../config";

export default function Drawer({ setDescription, active }) {
  const handleClick = useCallback(({ name, description, subTitle }) => {
    // handle the click event
    setDescription({
      description: description,
      subTitle: subTitle,
      active: name
    });
  }, []);

  return (
    <>
      <div
        className=" drawer panel-left"
        style={{
          position: "relative",
          margin: "15vh 0",
          left: 0,
          backgroundColor: "hsla(0, 0%, 0%, 0.5)",
          width: "35%",
          height: "100vh",
          float: "left",
          listStyle: "none",
    
          alignContent:"center",
          flexFlow: "column",
          justifyContent: "space-evenly"
        }}
      >
        {config.nosotros.map(n => {
          const { name, description, subTitle } = n;
          return (
            <li
              className={name === active ? "active" : ""}
              key={name}
              onClick={() => handleClick(n)}
            >
              <span
                className={name === active ? "label title active" : "label"}
              >
                {name}
              </span>
            </li>
          );
        })}
      </div>
    </>
  );
}
