import React, { useState, useCallback  } from 'react';
//import { Link } from 'gatsby';
import MediaQuery from 'react-responsive';
import config from "../../config";
import Layout from '../components/Layout';
import Drawer from '../components/Drawer';
import SEO from '../components/seo';
import Container from '../components/Container';
import { StaticImage } from "gatsby-plugin-image"


const TeamGallery = () => {

  return(
    <div className="team-gallery-container">
    
      <h4 className="team-gallery-main">FORMACIÓN ACADÉMICA </h4>
      <div className="team-gallery-item">
        <ul className="coreSans">
          <li>1996-2009 Colegio Columbus School Medellín </li>
          <li>2009-2011 Universidad Pontificia Universidad Javeriana - Bogotá 
          Escuela de arquitectura y diseño </li>
          <li>2011-2014 Universidad Pontificia Bolivariana-Medellín 
          Escuela de arquitectura y diseño </li>
          <li>2015 Instituto Europeo de Diseño - Barcelona 
          Diplomado en diseño de interiores</li>
        </ul>
      </div>
    </div>
  );
}

const IndexPage = () => {
  const [description, setDescription] = useState({
    description: '',
    active: 'MANIFIESTO'
  });
  let active = description.active;

  const handleClick = useCallback(({ name, description, subTitle}) => {
    // handle the click event
    setDescription({
      description: description,
      subTitle: subTitle,
      active: name
    });
  }, []);

  return (
    <Layout css={"bg-nosotros-img"}>
      <SEO title="Nosotros" />
      <Container  >
       
        <MediaQuery maxDeviceWidth={991 }>
          <div className="mobile">
          {            
            config.nosotros.map(n => {
          
            const { name, description, subTitle, team = false } = n;
            
              return (
                <div
                  className={ "active" }
                  key={name}
                  onClick={() => handleClick(n)}
                >
                  <span
                    className={"label title active" }
                  >
                    {name}
                  </span>
                  <h3 >{subTitle}</h3> 
                  <p className="coreSans">
                    {description}
                  </p>
                  {team && <TeamGallery>
                    </TeamGallery>
                    }
                  {team && <StaticImage src="../assets/images/Eduardo.jpg" 
                                        alt="Eduardo"
                                        placeholder="blurred"
                                        layout="fixed"
                                        width={319}
                                        height={420}
                                         />}
             
                </div>
              );
          })}
          </div>
      </MediaQuery>

      <MediaQuery minDeviceWidth={992} device={{ deviceWidth: 1600 }}>
        <Drawer setDescription={setDescription} active={active} />
          <div 
            style={{
              position: 'absolute',
              top: '0',
              right: 0,
              backgroundColor: 'hsla(0, 0%, 0%, 0.15)',
              width: '63%',
              height: '100%',
              margin: '1em',
              padding: '1.5em',
              paddingRight: '4vw',
              paddingLeft: '4vw',
              overflowY: 'hidden',
              marginBottom: '10vh'
            }}
            className="drawer coreSans"
          >

        { config.nosotros.map( n => {
              const { name, description, subTitle, team=false, innerDescription } = n;
              
              if(name === active) {
                  return (
                    <div  key={name} style={{
                      
                      display: 'grid',
                      alignContent: 'center'
                    }}>
                    <h3>{subTitle}</h3> 
                    <p>{description}</p>

                    {team && <TeamGallery>
                    </TeamGallery>
                    }
                    {team && <StaticImage src="../assets/images/Eduardo.jpg" 
                                          alt="Eduardo"
                                          placeholder="blurred"
                                          layout="fixed"
                                          width={460}
                                          height={560}
                                          style={{
                                            overflowY: 'hidden',
                                          }} 
                              />}                   
                  </div>
                )
              }              
            }
          )
        }
    
        </div>
      </MediaQuery>

      </Container>
    </Layout>
  );
};

export default React.memo(IndexPage);
